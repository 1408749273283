import React, { Component } from 'react';
import Img from "gatsby-image"

class DetailView extends Component {
    render() {
        const {img,children} = this.props;
        return (
            <div className="content-detail">
                {
                    (img && img.localFile && img.url.indexOf('FFFFFF')<0 && img.localFile.childImageSharp) &&
                    <div className="content-detail__image">
                        <Img alt={img.alt} fluid={img.localFile.childImageSharp.fluid} />
                    </div>
                }
                {children}
            </div>
        );
    }
}

export default DetailView;